import {
  CreateConverterTrackingParams,
  CreateLedgerTransactionError,
  CreateLedgerTransactionsError,
  DeleteLedgerTransactionError,
  DeleteReconciliationReportError,
  EditLedgerTransactionError,
  EditReconciliationReportDetailsError,
  IReconciliationReportRepository,
  LockReconciliationError,
  ManualReconciliationError,
  ReconciliationReportFetchError,
  SuggestedMatchesResponse,
  UnReconcileMatchError,
  ValidateDocumentResponse,
} from '.';
import { SuggestedMatchPrimitives, SuggestedMatchesStatuses } from '../../../models/SuggestedMatch';
import {
  AdjustmentsReport,
  Match,
  MatchType,
  ReconciliationAccountReport,
  ReconciliationReport,
  ReconciliationReportDetails,
  ReconciliationReport as ReconciliationReportModel,
  ReconciliationTransactionStatuses,
  TReconciliationTransactionStatus,
  Transaction,
} from '../../../models/ReconciliationReport';
import {
  IReconciliationReportService,
  SuggestionModel,
  ReconciliationReportModel as ServiceReconciliationModel,
  ReconciliationFinancialDocument,
  MatchModel,
  ReportContext,
  Transaction as TransactionModel,
  UploadDocumentRequestType,
  CreateReconciliationReportInputParams,
} from '../../interfaces/IReconciliationReportService';
import {
  CreateReconciliationReportData,
  CreateReconciliationReportParams,
} from '../../../models/CreateReconciliationReport';
import { ConvertFileParams, FileData, UploadFileParams } from '../../../models/UploadFileData';
import { IFileStorageService } from '../../interfaces/IFileStorageService';
import { LocalStorageRepository } from '../LocalStorage';
import { AsyncStatus, RepositoryResponse, State, fetchedState } from '../../../types';
import { EventBus, Events } from '../../../Events';
import {
  CreateLedgerTransactionParams,
  CreateLedgerTransactionsParams,
  DeleteLedgerTransactionParams,
  EditLedgerTransactionParams,
} from '../../../models/ManualReconciliation';

export default class ReconciliationReportRepository implements IReconciliationReportRepository {
  private reconciliationReportService: IReconciliationReportService =
    {} as IReconciliationReportService;

  private fileStorageService: IFileStorageService = {} as IFileStorageService;

  constructor(
    reconciliationReportService: IReconciliationReportService,
    fileStorageService: IFileStorageService
  ) {
    this.reconciliationReportService = reconciliationReportService;
    this.fileStorageService = fileStorageService;
  }

  async acceptAllSuggestedMatches(reconciliationReportId: string): Promise<void> {
    const accessToken = LocalStorageRepository.getAccessToken();
    if (!accessToken) throw new Error('No token found');
    try {
      await this.reconciliationReportService.acceptAllSuggestedMatches(
        reconciliationReportId,
        accessToken
      );
    } catch (error) {
      console.error('acceptSuggestedMatch error', error);
    }
  }

  async acceptSuggestedMatches(
    reconciliationReportId: string,
    suggestionIds: string[]
  ): Promise<void> {
    const accessToken = LocalStorageRepository.getAccessToken();
    if (!accessToken) throw new Error('No token found');
    try {
      await this.reconciliationReportService.acceptSuggestedMatches(
        reconciliationReportId,
        suggestionIds,
        accessToken
      );
    } catch (error) {
      console.error('acceptSuggestedMatches error', error);
    }
  }

  async rejectSuggestedMatches(
    reconciliationReportId: string,
    suggestionIds: string[]
  ): Promise<void> {
    const accessToken = LocalStorageRepository.getAccessToken();
    if (!accessToken) throw new Error('No token found');
    try {
      await this.reconciliationReportService.rejectSuggestedMatches(
        reconciliationReportId,
        suggestionIds,
        accessToken
      );
    } catch (error) {
      console.error('rejectSuggestedMatches error', error);
    }
  }

  async editReconciliationReportDetails(
    reconciliationReportId: string,
    reconciliationReportDetails: ReconciliationReportDetails
  ): Promise<void> {
    try {
      const accessToken = LocalStorageRepository.getAccessToken();
      if (!accessToken) throw new Error('No token found');
      await this.reconciliationReportService.editReconciliationReportDetails(
        reconciliationReportId,
        reconciliationReportDetails,
        accessToken
      );
      EventBus.emit(
        Events.EDIT_RECONCILIATION_REPORT_DETAILS,
        fetchedState({ reconciliationReportId, reconciliationReportDetails })
      );
    } catch (error: unknown) {
      const errorResponse: State<null, EditReconciliationReportDetailsError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error as EditReconciliationReportDetailsError,
      };
      EventBus.emit(Events.EDIT_RECONCILIATION_REPORT_DETAILS, errorResponse);
    }
  }

  getReconciliationReportsByAccountId(
    accountId: string
  ): State<ReconciliationAccountReport[], ReconciliationReportFetchError> {
    this.fetchReconciliationReportsByAccountId(accountId); // TODO remove this line as it is causing rerenderings
    return {
      status: AsyncStatus.CACHED,
      fetchedAt: Date.now(),
      data: null,
      error: null,
    };
  }

  getReconciliationReportsFromCurrentAccountId(): State<
    ReconciliationAccountReport[],
    ReconciliationReportFetchError
  > {
    const accountId = LocalStorageRepository.getAccountId();
    if (accountId) {
      this.fetchReconciliationReportsByAccountId(accountId);
    }
    return {
      status: AsyncStatus.CACHED,
      fetchedAt: Date.now(),
      data: null,
      error: null,
    };
  }

  private fetchReconciliationReportsByAccountId(accountId: string): void {
    const accessToken = LocalStorageRepository.getAccessToken();
    if (!accessToken) throw new Error('No token found');
    new Promise<State<ReconciliationAccountReport[], ReconciliationReportFetchError>>(
      // eslint-disable-next-line no-async-promise-executor
      async (resolve, reject) => {
        try {
          const reconciliationReports =
            await this.reconciliationReportService.getReconciliationReportsByAccountId(
              accountId,
              accessToken
            );
          // console.log('reconciliationReports', reconciliationReports);
          const reconciliationReportState: State<ReconciliationAccountReport[], null> =
            fetchedState(reconciliationReports);
          resolve(reconciliationReportState);
        } catch (error) {
          console.log('error', error);
          reject(error);
        }
      }
    )
      .then(
        (
          reconciliationReportsResponse: State<
            ReconciliationAccountReport[],
            ReconciliationReportFetchError
          >
        ) => {
          EventBus.emit(Events.RECONCILIATION_ACCOUNT_REPORTS_FETCH, reconciliationReportsResponse);
        }
      )
      .catch((error) => {
        const errorResponse: State<null, ReconciliationReportFetchError> = {
          status: AsyncStatus.ERROR,
          fetchedAt: Date.now(),
          data: null,
          error,
        };
        EventBus.emit(Events.RECONCILIATION_ACCOUNT_REPORTS_FETCH, errorResponse);
      });
  }

  async validateDocument(id: string, concreteType?: string): Promise<ValidateDocumentResponse> {
    const accessToken = LocalStorageRepository.getAccessToken();
    if (!accessToken) throw new Error('No token found');
    return this.reconciliationReportService.validateDocument(id, concreteType!, accessToken);
  }

  async uploadFile({
    file,
    fileName,
    fileType,
    documentType,
    isNotInStandardTemplateFormat,
    concreteType,
  }: UploadFileParams): Promise<string> {
    // file.name = documentType === "BANK_STATEMENT" ? "bank_statement.xlsx" : "ledger.xlsx";
    const workspaceId = LocalStorageRepository.getWorkspaceId();
    if (!workspaceId) throw new Error('No workspace found');
    const entityId = LocalStorageRepository.getEntityId();
    if (!entityId) throw new Error('No entity found');
    const accountId = LocalStorageRepository.getAccountId();
    if (!accountId) throw new Error('No reconciliation account found');
    const accessToken = LocalStorageRepository.getAccessToken();
    if (!accessToken) throw new Error('No token found');
    const fileLocation = await this.fileStorageService.uploadFile({
      file,
      fileName,
      fileType,
      workspaceId,
      entityId,
      accountId,
      accessToken,
    });

    const uploadDocumentRequestParams: UploadDocumentRequestType = {
      name: fileName,
      documentType,
      storagePath: fileLocation,
      fileType,
      accessToken,
      concreteType,
      entityId,
      workspaceId,
      accountId,
    };
    if (isNotInStandardTemplateFormat === true) {
      uploadDocumentRequestParams.isNotInStandardTemplateFormat = true;
    }
    const documentId = await this.reconciliationReportService.uploadDocument(
      uploadDocumentRequestParams
    );
    return documentId;
  }

  async convertFile({ file, fileType }: ConvertFileParams): Promise<FileData[]> {
    const workspaceId = LocalStorageRepository.getWorkspaceId();
    if (!workspaceId) throw new Error('No workspace found');
    const accessToken = LocalStorageRepository.getAccessToken();
    if (!accessToken) throw new Error('No token found');
    const fileResult = await this.fileStorageService.convertFile({
      file,
      fileType,
      accessToken,
      workspaceId,
    });

    return fileResult;
  }

  async createConverterTracking(params: CreateConverterTrackingParams): Promise<void> {
    const { format, type } = params;
    const workspaceId = LocalStorageRepository.getWorkspaceId();
    if (!workspaceId) throw new Error('No workspace found');
    const accessToken = LocalStorageRepository.getAccessToken();
    if (!accessToken) throw new Error('No token found');
    try {
      await this.reconciliationReportService.createConverterTracking(
        { workspaceId, format, type },
        accessToken
      );
    } catch (error) {
      console.error('createConverterTracking error', error);
    }
  }

  getSuggestedMatches(
    reconciliationReportId: string,
    getSuggestedMatchesCallback: (
      suggestedMatchesResponse: RepositoryResponse<SuggestedMatchesResponse>
    ) => void
  ): SuggestedMatchesResponse {
    const accessToken = LocalStorageRepository.getAccessToken();
    if (!accessToken) throw new Error('No token found');
    // eslint-disable-next-line no-async-promise-executor
    new Promise<RepositoryResponse<SuggestedMatchesResponse>>(async (resolve, reject) => {
      try {
        const suggestions = await this.reconciliationReportService.getSuggestions(
          reconciliationReportId,
          accessToken
        );
        const data: SuggestedMatchPrimitives[] = this.mapSuggestionsToSuggestedMatches(suggestions);
        resolve({
          ok: {
            status: SuggestedMatchesStatuses.SUCCESS,
            data,
          },
          error: null,
        });
      } catch (error) {
        console.error('error', error);
        reject(error);
      }
    })
      .then((suggestedMatchesResponse: RepositoryResponse<SuggestedMatchesResponse>) => {
        if (getSuggestedMatchesCallback) getSuggestedMatchesCallback(suggestedMatchesResponse);
      })
      .catch(() => {
        if (getSuggestedMatchesCallback !== null)
          getSuggestedMatchesCallback({
            ok: null,
            error: {
              code: '500',
              message: 'Repository Error',
            },
          });
      });
    return {
      status: SuggestedMatchesStatuses.CACHED,
      data: [],
    };
  }

  async createReconciliationReport(
    params: CreateReconciliationReportParams,
    onData: (data: CreateReconciliationReportData) => void,
    onCompleted: () => void,
    onError: (error: unknown) => void
  ): Promise<void> {
    const workspaceId = LocalStorageRepository.getWorkspaceId();
    if (!workspaceId) throw new Error('No workspace found');
    const entityId = LocalStorageRepository.getEntityId();
    if (!entityId) throw new Error('No entity found');
    const accountId = LocalStorageRepository.getAccountId();
    if (!accountId) throw new Error('No reconciliation account found');
    const accessToken = LocalStorageRepository.getAccessToken();
    if (!accessToken) throw new Error('No token found');
    const reportContext: ReportContext = {
      workspaceId,
      entityId,
      accountId,
    };
    const createReportServiceParams: CreateReconciliationReportInputParams = {
      ...params,
      externalDebitIncrease: params.bankDebitIncrease,
    };
    await this.reconciliationReportService.createReconciliationReport(
      createReportServiceParams,
      reportContext,
      accessToken,
      onData,
      onCompleted,
      onError
    );
  }

  async acceptSuggestedMatch(
    reconciliationReportId: string,
    suggestedMatchId: string
  ): Promise<void> {
    const accessToken = LocalStorageRepository.getAccessToken();
    if (!accessToken) throw new Error('No token found');
    try {
      await this.reconciliationReportService.acceptSuggestedMatch(
        reconciliationReportId,
        suggestedMatchId,
        accessToken
      );
    } catch (error) {
      console.error('acceptSuggestedMatch error', error);
    }
  }

  async rejectSuggestedMatch(
    reconciliationReportId: string,
    suggestedMatchId: string
  ): Promise<void> {
    const accessToken = LocalStorageRepository.getAccessToken();
    if (!accessToken) throw new Error('No token found');
    await this.reconciliationReportService.rejectSuggestedMatch(
      reconciliationReportId,
      suggestedMatchId,
      accessToken
    );
  }

  async unReconcileMatch(reconciliationReportId: string, matchId: string): Promise<void> {
    try {
      const accessToken = LocalStorageRepository.getAccessToken();
      if (!accessToken) throw new Error('No token found');
      // TODO chnage the interface since it seems workspaceId, entityId and accountId are not used
      await this.reconciliationReportService.unreconcileMatch(
        { reconciliationReportId, matchId },
        accessToken
      );
      EventBus.emit(Events.UN_RECONCILE_MATCH, fetchedState<null>(null));
    } catch (error: unknown) {
      const errorResponse: State<null, UnReconcileMatchError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: (error as Error).toString(),
      };
      EventBus.emit(Events.UN_RECONCILE_MATCH, errorResponse);
    }
  }

  async lockReconciliationReport(reconciliationReportId: string): Promise<void> {
    try {
      const accessToken = LocalStorageRepository.getAccessToken();
      if (!accessToken) throw new Error('No token found');
      // TODO chnage the interface since it seems workspaceId, entityId and accountId are not used
      await this.reconciliationReportService.lockReconciliationReport(
        { reconciliationReportId, workspaceId: '', entityId: '', accountId: '' },
        accessToken
      );
      EventBus.emit(Events.LOCK_RECONCILIATION_REPORT, fetchedState<null>(null));
    } catch (error: unknown) {
      const errorResponse: State<null, LockReconciliationError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: (error as Error).toString(),
      };
      EventBus.emit(Events.LOCK_RECONCILIATION_REPORT, errorResponse);
    }
  }

  async deleteReconciliationReport(reconciliationReportId: string): Promise<void> {
    try {
      const accessToken = LocalStorageRepository.getAccessToken();
      if (!accessToken) throw new Error('No token found');
      await this.reconciliationReportService.deleteReconciliationReport(
        reconciliationReportId,
        accessToken
      );
      EventBus.emit(Events.DELETE_RECONCILIATION_REPORT, fetchedState(reconciliationReportId));
    } catch (error: unknown) {
      const errorResponse: State<null, DeleteReconciliationReportError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error as DeleteReconciliationReportError,
      };
      EventBus.emit(Events.DELETE_RECONCILIATION_REPORT, errorResponse);
    }
  }

  private mapSuggestionsToSuggestedMatches(
    suggestions: SuggestionModel[]
  ): SuggestedMatchPrimitives[] {
    return suggestions.map(this.mapSuggestionToSuggestedMatch);
  }

  private mapSuggestionToSuggestedMatch(suggestion: SuggestionModel): SuggestedMatchPrimitives {
    return {
      id: suggestion.id,
      bankStatementTransactions: suggestion.bankStatementTransactions,
      ledgerTransactions: suggestion.ledgerTransactions,
    };
  }

  private mapReconciliationReportToReconciliationReportModel(
    report: ServiceReconciliationModel
  ): ReconciliationReportModel {
    return {
      id: report.id,
      ledgerEndBalance: report.ledgerEndOfPeriodBalance,
      bankClosingBalance: report.externalEndOfPeriodBalance,
      ledgerTransactions: this.mapDocumentToTransactions(report.ledgerDocument),
      externalTransactions: this.mapDocumentToTransactions(report.externalDocument),
      matches: this.mapMatchModelsToMatches(report.matches),
      suggestions: this.mapSuggestionsToSuggestedMatches(report.suggestedMatches),
      status: report.status,
      name: report.name,
      reconciliationPeriod: report.reconciliationPeriod,
    };
  }

  private mapMatchModelToMatch(match: MatchModel): Match {
    return {
      id: match.id,
      bankStatementTransactions: this.mapTransactionsModelToTransactions(
        match.bankStatementTransactions
      ),
      ledgerTransactions: this.mapTransactionsModelToTransactions(match.ledgerTransactions),
      type: this.mapMatchCreatedByToMatchType(match),
    };
  }

  private mapMatchModelsToMatches(matches: MatchModel[]): Match[] {
    return matches.map(this.mapMatchModelToMatch.bind(this));
  }

  private mapMatchCreatedByToMatchType(match: MatchModel): MatchType {
    if (match.createdBy === null || match.createdBy === 'null' || match.createdBy === '') {
      return 'A';
    }
    if (match.createdBy.startsWith('Rule') || match.createdBy.startsWith('Step')) {
      return 'A';
    }
    if (match.isCreatedFromSuggestion) {
      return 'S';
    }
    return 'M';
  }

  private mapTransactionsModelToTransactions(transactionsModel: TransactionModel[]): Transaction[] {
    const transactions: Transaction[] = [];
    transactionsModel.forEach((transaction) => {
      transactions.push({
        date: transaction.date,
        description: transaction.description,
        reference: transaction.reference,
        debit: transaction.debit,
        credit: transaction.credit,
        rowId: transaction.rowId,
        status: this.mapStatusToReconciliationTransactionStatus(transaction.status),
        financialTransactionId: transaction.financialTransactionId,
      });
    });
    return transactions;
  }

  private mapStatusToReconciliationTransactionStatus(
    status: string
  ): TReconciliationTransactionStatus {
    if (status === ReconciliationTransactionStatuses.Reconciled) {
      return ReconciliationTransactionStatuses.Reconciled;
    }
    if (status === ReconciliationTransactionStatuses.Unreconciled) {
      return ReconciliationTransactionStatuses.Unreconciled;
    }
    if (status === ReconciliationTransactionStatuses.Suggestion) {
      return ReconciliationTransactionStatuses.Suggestion;
    }
    if (status === ReconciliationTransactionStatuses.Reversed) {
      return ReconciliationTransactionStatuses.Reversed;
    }
    throw new Error('Invalid reconciliation transaction status');
  }

  private mapDocumentToTransactions(document: ReconciliationFinancialDocument): Transaction[] {
    const transactions = document.transactions.map((transaction) => ({
      date: transaction.date,
      description: transaction.description,
      reference: transaction.reference,
      debit: transaction.debit,
      credit: transaction.credit,
      rowId: transaction.rowId,
      status: this.mapStatusToReconciliationTransactionStatus(transaction.status),
      financialTransactionId: transaction.financialTransactionId,
    }));
    return transactions;
  }

  getReconciliationReport(
    reconciliationReportId: string
  ): State<ReconciliationReport | null, ReconciliationReportFetchError> {
    this.fetchReconciliationReport(reconciliationReportId);
    return {
      status: AsyncStatus.CACHED,
      fetchedAt: Date.now(),
      data: null,
      error: null,
    };
  }

  fetchReconciliationReport(reconciliationReportId: string): void {
    const accessToken = LocalStorageRepository.getAccessToken();
    if (!accessToken) throw new Error('No token found');
    new Promise<State<ReconciliationReport | null, ReconciliationReportFetchError>>(
      // eslint-disable-next-line no-async-promise-executor
      async (resolve, reject) => {
        try {
          const serviceReconciliationReport =
            await this.reconciliationReportService.getReconciliationReport(
              reconciliationReportId,
              accessToken
            );
          const reconciliationReport = this.mapReconciliationReportToReconciliationReportModel(
            serviceReconciliationReport
          );
          const reconciliationReportState: State<ReconciliationReport | null, null> =
            fetchedState(reconciliationReport);
          resolve(reconciliationReportState);
        } catch (error) {
          console.log('error', error);
          reject(error);
        }
      }
    )
      .then(
        (
          reconciliationReportResponse: State<
            ReconciliationReport | null,
            ReconciliationReportFetchError
          >
        ) => {
          EventBus.emit(Events.RECONCILIATION_REPORT_FETCH, reconciliationReportResponse);
        }
      )
      .catch((error) => {
        const errorResponse: State<null, ReconciliationReportFetchError> = {
          status: AsyncStatus.ERROR,
          fetchedAt: Date.now(),
          data: null,
          error,
        };
        EventBus.emit(Events.RECONCILIATION_REPORT_FETCH, errorResponse);
      });
  }

  async getReconciliationReportSync(reconciliationReportId: string): Promise<ReconciliationReport> {
    const accessToken = LocalStorageRepository.getAccessToken();
    if (!accessToken) throw new Error('No token found');

    const serviceReconciliationReport =
      await this.reconciliationReportService.getReconciliationReport(
        reconciliationReportId,
        accessToken
      );
    const reconciliationReport = this.mapReconciliationReportToReconciliationReportModel(
      serviceReconciliationReport
    );
    return reconciliationReport;
  }

  async manualReconciliation(
    reconciliationReportId: string,
    ledgerTransactionRowIds: number[],
    externalTransactionRowIds: number[]
  ): Promise<void> {
    try {
      const accessToken = LocalStorageRepository.getAccessToken();
      if (!accessToken) throw new Error('No token found');
      await this.reconciliationReportService.manuallyReconcile(
        reconciliationReportId,
        ledgerTransactionRowIds,
        externalTransactionRowIds,
        accessToken
      );
      EventBus.emit(Events.MANUAL_RECONCILIATION, fetchedState(null));
    } catch (error: unknown) {
      const errorResponse: State<null, ManualReconciliationError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error as ManualReconciliationError,
      };
      EventBus.emit(Events.MANUAL_RECONCILIATION, errorResponse);
    }
  }

  /**
   * Used by the download button for the adjustments report,
   * hence it is not implemented using the state pattern.
   */
  async getAdjustmentsReport(reportId: string): Promise<AdjustmentsReport> {
    const accessToken = LocalStorageRepository.getAccessToken();
    if (!accessToken) throw new Error('No token found');
    const adjustmentsReport = await this.reconciliationReportService.getAdjustmentsReport(
      { reconciliationReportId: reportId },
      accessToken
    );
    return adjustmentsReport;
  }

  async createLedgerTransaction(
    createLedgerTransactionParams: CreateLedgerTransactionParams
  ): Promise<void> {
    try {
      const accessToken = LocalStorageRepository.getAccessToken();
      if (!accessToken) throw new Error('No token found');
      const ledgerTransactionRowId = await this.reconciliationReportService.createLedgerTransaction(
        createLedgerTransactionParams,
        accessToken
      );
      EventBus.emit(Events.CREATE_LEDGER_TRANSACTION, fetchedState(ledgerTransactionRowId));
    } catch (error: unknown) {
      const errorResponse: State<null, CreateLedgerTransactionError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error as CreateLedgerTransactionError,
      };
      EventBus.emit(Events.CREATE_LEDGER_TRANSACTION, errorResponse);
    }
  }

  async createLedgerTransactions(
    createLedgerTransactionParams: CreateLedgerTransactionsParams
  ): Promise<void> {
    try {
      const accessToken = LocalStorageRepository.getAccessToken();
      if (!accessToken) throw new Error('No token found');
      const ledgerTransactionRowIds =
        await this.reconciliationReportService.createLedgerTransactions(
          createLedgerTransactionParams,
          accessToken
        );
      EventBus.emit(Events.CREATE_LEDGER_TRANSACTIONS, fetchedState(ledgerTransactionRowIds));
    } catch (error: unknown) {
      const errorResponse: State<null, CreateLedgerTransactionsError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error as CreateLedgerTransactionsError,
      };
      EventBus.emit(Events.CREATE_LEDGER_TRANSACTIONS, errorResponse);
    }
  }

  async editLedgerTransaction(
    editLedgerTransactionParams: EditLedgerTransactionParams
  ): Promise<void> {
    try {
      const accessToken = LocalStorageRepository.getAccessToken();
      if (!accessToken) throw new Error('No token found');
      const ledgerRowId = await this.reconciliationReportService.editLedgerTransaction(
        editLedgerTransactionParams,
        accessToken
      );
      EventBus.emit(Events.EDIT_LEDGER_TRANSACTION, fetchedState(ledgerRowId));
    } catch (error: unknown) {
      const errorResponse: State<null, EditLedgerTransactionError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error as EditLedgerTransactionError,
      };
      EventBus.emit(Events.EDIT_LEDGER_TRANSACTION, errorResponse);
    }
  }

  async deleteLedgerTransaction(
    deleteLedgerTransactionParams: DeleteLedgerTransactionParams
  ): Promise<void> {
    try {
      const accessToken = LocalStorageRepository.getAccessToken();
      if (!accessToken) throw new Error('No token found');
      const ledgerRowId = await this.reconciliationReportService.deleteLedgerTransaction(
        deleteLedgerTransactionParams,
        accessToken
      );
      EventBus.emit(Events.DELETE_LEDGER_TRANSACTION, fetchedState(ledgerRowId));
    } catch (error: unknown) {
      const errorResponse: State<null, DeleteLedgerTransactionError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error as DeleteLedgerTransactionError,
      };
      EventBus.emit(Events.DELETE_LEDGER_TRANSACTION, errorResponse);
    }
  }
}
