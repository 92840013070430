import { EventEmitter } from 'eventemitter3';
import { State } from './types';
import {
  EntityFetchError,
  EntitySettingsFetchError,
  ReconciliationAccountFetchError,
  UpdateEntityError,
  UpdateReconciliationAccountError,
  UpdateWorkspaceError,
  WorkspaceFetchError,
  WorkspaceSettingsFetchError,
} from './infra/repositories/resources';
import { Workspace } from './infra/interfaces/IResourcesService';
import {
  Category,
  CategoryItem,
  Entity,
  EntitySettings,
  ReconciliationAccount,
  StopListItem,
  WorkspaceSettings,
} from './models/Resources';
import {
  ReconciliationAccountReport,
  ReconciliationReport,
  ReconciliationReportDetails,
} from './models/ReconciliationReport';
import {
  CreateLedgerTransactionError,
  CreateLedgerTransactionsError,
  DeleteLedgerTransactionError,
  DeleteReconciliationReportError,
  EditLedgerTransactionError,
  EditReconciliationReportDetailsError,
  BaseError,
  LockReconciliationError,
  ManualReconciliationError,
  ReconciliationReportFetchError,
  UnReconcileMatchError,
} from './infra/repositories/reconciliation-report';
import { User } from './models/User';
import {
  EntityUserCreatedError,
  GetUserError,
  GetUsersError,
  ReconciliationAccountUserCreatedError,
  RenewAccessTokenError,
  UpdateUserError,
  WorkspaceUserCreatedError,
} from './infra/repositories/iam';
import { BitloopsError } from './Errors';
import { DOCUMENT_TYPE } from './models/UploadFileData';

export enum Events {
  WORKSPACES_FETCH_SELECTED = 'WORKSPACES_FETCH_SELECTED',
  WORKSPACES_FETCH = 'WORKSPACES_FETCH',
  WORKSPACE_SELECT = 'WORKSPACE_SELECT',
  WORKSPACE_DELETE = 'WORKSPACE_DELETE',
  WORKSPACE_DELETE_LAST = 'WORKSPACE_DELETE_LAST',
  WORKSPACE_CREATE = 'WORKSPACE_CREATE',
  WORKSPACE_UPDATE = 'WORKSPACE_UPDATE',
  ENTITY_SELECT = 'ENTITY_SELECT',
  ENTITY_DELETE = 'ENTITY_DELETE',
  ENTITY_CREATE = 'ENTITY_CREATE',
  ENTITY_FETCH = 'ENTITY_FETCH',
  ENTITY_UPDATE = 'ENTITY_UPDATE',
  RECONCILIATION_ACCOUNT_SELECT = 'RECONCILIATION_ACCOUNT_SELECT',
  RECONCILIATION_ACCOUNT_DELETE = 'RECONCILIATION_ACCOUNT_DELETE',
  RECONCILIATION_ACCOUNT_CREATE = 'RECONCILIATION_ACCOUNT_CREATE',
  RECONCILIATION_ACCOUNT_UPDATE = 'RECONCILIATION_ACCOUNT_UPDATE',
  RECONCILIATION_ACCOUNT_FETCH = 'RECONCILIATION_ACCOUNT_FETCH',
  RECONCILIATION_REPORT_FETCH = 'RECONCILIATION_REPORT_FETCH',
  RECONCILIATION_ACCOUNT_REPORTS_FETCH = 'RECONCILIATION_ACCOUNT_REPORTS_FETCH',

  // Workspace & Entity Settings
  WORKSPACE_SETTINGS_FETCH = 'WORKSPACE_SETTINGS_FETCH',
  WORKSPACE_STOP_LIST_ITEM_CREATE = 'WORKSPACE_STOP_LIST_ITEM_CREATE',
  WORKSPACE_STOP_LIST_ITEM_UPDATE = 'WORKSPACE_STOP_LIST_ITEM_UPDATE',
  WORKSPACE_STOP_LIST_ITEM_DELETE = 'WORKSPACE_STOP_LIST_ITEM_DELETE',
  WORKSPACE_CATEGORY_CREATE = 'WORKSPACE_CATEGORY_CREATE',
  WORKSPACE_CATEGORY_UPDATE = 'WORKSPACE_CATEGORY_UPDATE',
  WORKSPACE_CATEGORY_DELETE = 'WORKSPACE_CATEGORY_DELETE',
  WORKSPACE_CATEGORY_ITEM_CREATE = 'WORKSPACE_CATEGORY_ITEM_CREATE',
  WORKSPACE_CATEGORY_ITEM_UPDATE = 'WORKSPACE_CATEGORY_ITEM_UPDATE',
  WORKSPACE_CATEGORY_ITEM_DELETE = 'WORKSPACE_CATEGORY_ITEM_DELETE',
  WORKSPACE_USER_CREATED = 'WORKSPACE_USER_CREATED',
  ENTITY_USER_CREATED = 'ENTITY_USER_CREATED',
  RECONCILIATION_ACCOUNT_USER_CREATED = 'RECONCILIATION_ACCOUNT_USER_CREATED',
  ENTITY_SETTINGS_FETCH = 'ENTITY_SETTINGS_FETCH',
  ENTITY_WORKSPACE_STOP_LIST_ITEM_ENABLE = 'ENTITY_WORKSPACE_STOP_LIST_ITEM_ENABLE',
  ENTITY_WORKSPACE_STOP_LIST_ITEM_DISABLE = 'ENTITY_WORKSPACE_STOP_LIST_ITEM_DISABLE',
  ENTITY_WORKSPACE_CATEGORY_ENABLE = 'ENTITY_WORKSPACE_CATEGORY_ENABLE',
  ENTITY_WORKSPACE_CATEGORY_DISABLE = 'ENTITY_WORKSPACE_CATEGORY_DISABLE',
  ENTITY_STOP_LIST_ITEM_CREATE = 'ENTITY_STOP_LIST_ITEM_CREATE',
  ENTITY_STOP_LIST_ITEM_UPDATE = 'ENTITY_STOP_LIST_ITEM_UPDATE',
  ENTITY_STOP_LIST_ITEM_DELETE = 'ENTITY_STOP_LIST_ITEM_DELETE',
  ENTITY_CATEGORY_CREATE = 'ENTITY_CATEGORY_CREATE',
  ENTITY_CATEGORY_UPDATE = 'ENTITY_CATEGORY_UPDATE',
  ENTITY_CATEGORY_DELETE = 'ENTITY_CATEGORY_DELETE',
  ENTITY_CATEGORY_ITEM_CREATE = 'ENTITY_CATEGORY_ITEM_CREATE',
  ENTITY_CATEGORY_ITEM_UPDATE = 'ENTITY_CATEGORY_ITEM_UPDATE',
  ENTITY_CATEGORY_ITEM_DELETE = 'ENTITY_CATEGORY_ITEM_DELETE',
  USER_FETCH = 'USER_FETCH',
  USER_UPDATE = 'USER_UPDATE',
  MANUAL_RECONCILIATION = 'MANUAL_RECONCILIATION',
  LOCK_RECONCILIATION_REPORT = 'LOCK_RECONCILIATION_REPORT',
  UN_RECONCILE_MATCH = 'UN_RECONCILE_MATCH',
  USERS_FETCH = 'USERS_FETCH',
  CREATE_LEDGER_TRANSACTION = 'CREATE_LEDGER_TRANSACTION',
  EDIT_LEDGER_TRANSACTION = 'EDIT_LEDGER_TRANSACTION',
  DELETE_LEDGER_TRANSACTION = 'DELETE_LEDGER_TRANSACTION',
  WORKSPACE_USERS_FETCH = 'WORKSPACE_USERS_FETCH',
  ENTITY_USERS_FETCH = 'ENTITY_USERS_FETCH',
  RECONCILIATION_ACCOUNT_USERS_FETCH = 'RECONCILIATION_ACCOUNT_USERS_FETCH',
  EDIT_RECONCILIATION_REPORT_DETAILS = 'EDIT_RECONCILIATION_REPORT_DETAILS',
  RENEW_ACCESS_TOKEN = 'RENEW_ACCESS_TOKEN',
  ERROR = 'ERROR',
  CREATE_LEDGER_TRANSACTIONS = 'CREATE_LEDGER_TRANSACTIONS',
  DELETE_RECONCILIATION_REPORT = 'DELETE_RECONCILIATION_REPORT',
  LEDGER_DEBIT_INCREASED = 'LEDGER_DEBIT_INCREASED',
  LEDGER_DEBIT_DECREASED = 'LEDGER_DEBIT_DECREASED',
  BANK_DEBIT_INCREASED = 'BANK_DEBIT_INCREASED',
  BANK_DEBIT_DECREASED = 'BANK_DEBIT_DECREASED',
  DOCUMENT_UPLOADED = 'DOCUMENT_UPLOADED',
  BANK_OB_UPLOAD_STARTED = 'BANK_OB_UPLOAD_STARTED',
  LEDGER_OB_UPLOAD_STARTED = 'LEDGER_OB_UPLOAD_STARTED',
}

export type UserDetailsEvent =
  | Events.WORKSPACE_USERS_FETCH
  | Events.ENTITY_USERS_FETCH
  | Events.RECONCILIATION_ACCOUNT_USERS_FETCH;

type WorkspaceId = string;

type EntityId = string;

type ReconciliationAccountId = string;

export type StopListItemId = string;
export type CategoryId = string;
type CategoryItemId = string;

export type CategoryItemPayload = CategoryItem & { categoryId: CategoryId };
export type CategoryItemDeletedPayload = { itemId: CategoryItemId; categoryId: string };
type UserId = string;

type ledgerTransactionRowId = number;

type ledgerTransactionRowIds = number[];

type ReconciliationReportId = string;

export type DocumentUploadedPayload = {
  openingBalance: number | null;
  type: DOCUMENT_TYPE;
  isOpeningBalanceSet: boolean;
};

export type EditReconciliationReportDetailsPayload = {
  reconciliationReportId: string;
  reconciliationReportDetails: ReconciliationReportDetails;
};

type EventMap = {
  [Events.WORKSPACES_FETCH_SELECTED]: State<Workspace | null, WorkspaceFetchError>; // P: ResourcesRepo, S: WorkspaceVM
  [Events.WORKSPACES_FETCH]: State<Workspace[], WorkspaceFetchError>; // P: ResourcesRepo, S: WorkspaceVM
  [Events.WORKSPACE_CREATE]: State<Workspace, WorkspaceFetchError>; // P: ResourcesRepo, S: WorkspaceVM
  [Events.WORKSPACE_SELECT]: State<Workspace, WorkspaceFetchError>; // P: WorkspaceVM, S: ResourcesRepo, EntityVM, ReconciliationAccountVM
  [Events.WORKSPACE_DELETE]: State<WorkspaceId, WorkspaceFetchError>; // P: ResourcesRepo, S: WorkspaceVM
  [Events.WORKSPACE_DELETE_LAST]: null; // P: ResourcesRepo, S: WorkspaceVM
  [Events.ENTITY_SELECT]: State<Entity | null, EntityFetchError>; // P: EntityVM, S: ResourcesRepo, ReconciliationAccountVM
  [Events.ENTITY_DELETE]: State<EntityId | null, EntityFetchError>; // P: ResourcesRepo, S: EntityVM, WorkspaceVM
  [Events.ENTITY_CREATE]: State<Entity | null, EntityFetchError>; // P: ResourcesRepo, S: WorkspaceVM, EntityVM
  [Events.ENTITY_FETCH]: State<Entity | null, EntityFetchError>; // P: ResourcesRepo, S: EntityVM
  [Events.RECONCILIATION_ACCOUNT_FETCH]: State<
    ReconciliationAccount | null,
    ReconciliationAccountFetchError
  >; // P: ResourcesRepo, S: ReconciliationAccountVM
  [Events.RECONCILIATION_ACCOUNT_CREATE]: State<
    ReconciliationAccount | null,
    ReconciliationAccountFetchError
  >; // P: ResourcesRepo, S: ReconciliationAccountVM
  [Events.RECONCILIATION_ACCOUNT_SELECT]: State<
    ReconciliationAccount | null,
    ReconciliationAccountFetchError
  >; // P: ReconciliationAccountVM, S: ResourcesRepo
  [Events.RECONCILIATION_ACCOUNT_DELETE]: State<
    ReconciliationAccountId,
    ReconciliationAccountFetchError
  >; // P: ResourcesRepo, S: ReconciliationAccountVM, EntityVM
  [Events.RECONCILIATION_REPORT_FETCH]: State<
    ReconciliationReport | null,
    ReconciliationReportFetchError
  >;
  [Events.RECONCILIATION_ACCOUNT_REPORTS_FETCH]: State<
    ReconciliationAccountReport[],
    ReconciliationReportFetchError
  >;
  [Events.WORKSPACE_UPDATE]: State<Workspace, UpdateWorkspaceError>;
  [Events.ENTITY_UPDATE]: State<Entity, UpdateEntityError>;
  [Events.USER_FETCH]: State<User, GetUserError>;
  [Events.USER_UPDATE]: State<null, UpdateUserError>;
  [Events.RECONCILIATION_ACCOUNT_UPDATE]: State<
    ReconciliationAccount,
    UpdateReconciliationAccountError
  >;
  [Events.WORKSPACE_SETTINGS_FETCH]: State<WorkspaceSettings, WorkspaceSettingsFetchError>;
  [Events.WORKSPACE_STOP_LIST_ITEM_CREATE]: State<StopListItem, WorkspaceSettingsFetchError>;
  [Events.WORKSPACE_STOP_LIST_ITEM_UPDATE]: State<StopListItem, WorkspaceSettingsFetchError>;
  [Events.WORKSPACE_STOP_LIST_ITEM_DELETE]: State<StopListItemId, WorkspaceSettingsFetchError>;
  [Events.WORKSPACE_CATEGORY_CREATE]: State<Category, WorkspaceSettingsFetchError>;
  [Events.WORKSPACE_CATEGORY_UPDATE]: State<Category, WorkspaceSettingsFetchError>;
  [Events.WORKSPACE_CATEGORY_DELETE]: State<CategoryId, WorkspaceSettingsFetchError>;
  [Events.WORKSPACE_CATEGORY_ITEM_CREATE]: State<CategoryItemPayload, WorkspaceSettingsFetchError>;
  [Events.WORKSPACE_CATEGORY_ITEM_UPDATE]: State<CategoryItemPayload, WorkspaceSettingsFetchError>;
  [Events.WORKSPACE_CATEGORY_ITEM_DELETE]: State<
    CategoryItemDeletedPayload,
    WorkspaceSettingsFetchError
  >;
  [Events.WORKSPACE_USER_CREATED]: State<UserId, WorkspaceUserCreatedError>;
  [Events.ENTITY_USER_CREATED]: State<UserId, EntityUserCreatedError>;
  [Events.RECONCILIATION_ACCOUNT_USER_CREATED]: State<
    UserId,
    ReconciliationAccountUserCreatedError
  >;
  [Events.ENTITY_SETTINGS_FETCH]: State<EntitySettings, EntitySettingsFetchError>;
  [Events.ENTITY_WORKSPACE_STOP_LIST_ITEM_ENABLE]: State<StopListItemId, EntitySettingsFetchError>;
  [Events.ENTITY_WORKSPACE_STOP_LIST_ITEM_DISABLE]: State<StopListItemId, EntitySettingsFetchError>;
  [Events.ENTITY_WORKSPACE_CATEGORY_ENABLE]: State<CategoryId, EntitySettingsFetchError>;
  [Events.ENTITY_WORKSPACE_CATEGORY_DISABLE]: State<CategoryId, EntitySettingsFetchError>;
  [Events.ENTITY_STOP_LIST_ITEM_CREATE]: State<StopListItem, EntitySettingsFetchError>;
  [Events.ENTITY_STOP_LIST_ITEM_UPDATE]: State<StopListItem, EntitySettingsFetchError>;
  [Events.ENTITY_STOP_LIST_ITEM_DELETE]: State<StopListItemId, EntitySettingsFetchError>;
  [Events.ENTITY_CATEGORY_CREATE]: State<Category, EntitySettingsFetchError>;
  [Events.ENTITY_CATEGORY_UPDATE]: State<Omit<Category, 'items'>, EntitySettingsFetchError>;
  [Events.ENTITY_CATEGORY_DELETE]: State<CategoryId, EntitySettingsFetchError>;
  [Events.ENTITY_CATEGORY_ITEM_CREATE]: State<CategoryItemPayload, EntitySettingsFetchError>;
  [Events.ENTITY_CATEGORY_ITEM_UPDATE]: State<CategoryItemPayload, EntitySettingsFetchError>;
  [Events.ENTITY_CATEGORY_ITEM_DELETE]: State<CategoryItemDeletedPayload, EntitySettingsFetchError>;
  [Events.MANUAL_RECONCILIATION]: State<null, ManualReconciliationError>;
  [Events.LOCK_RECONCILIATION_REPORT]: State<null, LockReconciliationError>;
  [Events.UN_RECONCILE_MATCH]: State<null, UnReconcileMatchError>;
  [Events.USERS_FETCH]: State<User[], UnReconcileMatchError>;
  [Events.CREATE_LEDGER_TRANSACTION]: State<ledgerTransactionRowId, CreateLedgerTransactionError>;
  [Events.EDIT_LEDGER_TRANSACTION]: State<ledgerTransactionRowId, EditLedgerTransactionError>;
  [Events.DELETE_LEDGER_TRANSACTION]: State<ledgerTransactionRowId, DeleteLedgerTransactionError>;
  [Events.USERS_FETCH]: State<User[], GetUsersError>;
  [Events.WORKSPACE_USERS_FETCH]: State<User[], GetUsersError>;
  [Events.ENTITY_USERS_FETCH]: State<User[], GetUsersError>;
  [Events.RECONCILIATION_ACCOUNT_USERS_FETCH]: State<User[], GetUsersError>;
  [Events.EDIT_RECONCILIATION_REPORT_DETAILS]: State<
    EditReconciliationReportDetailsPayload,
    EditReconciliationReportDetailsError
  >;
  [Events.RENEW_ACCESS_TOKEN]: State<User | null, RenewAccessTokenError>;
  [Events.ERROR]: BitloopsError;
  [Events.CREATE_LEDGER_TRANSACTIONS]: State<
    ledgerTransactionRowIds,
    CreateLedgerTransactionsError
  >;
  [Events.DELETE_RECONCILIATION_REPORT]: State<
    ReconciliationReportId,
    DeleteReconciliationReportError
  >;
  [Events.LEDGER_DEBIT_INCREASED]: State<null, BaseError>;
  [Events.LEDGER_DEBIT_DECREASED]: State<null, BaseError>;
  [Events.BANK_DEBIT_INCREASED]: State<null, BaseError>;
  [Events.BANK_DEBIT_DECREASED]: State<null, BaseError>;
  [Events.DOCUMENT_UPLOADED]: State<DocumentUploadedPayload, BaseError>;
  [Events.BANK_OB_UPLOAD_STARTED]: State<null, BaseError>;
  [Events.LEDGER_OB_UPLOAD_STARTED]: State<null, BaseError>;
};

class EventBus {
  // eslint-disable-next-line no-use-before-define
  private static _instance: EventBus;

  private emitter: EventEmitter;

  private constructor() {
    this.emitter = new EventEmitter();
  }

  public static get instance(): EventBus {
    if (!EventBus._instance) {
      EventBus._instance = new EventBus();
    }
    return EventBus._instance;
  }

  public subscribe<T extends keyof EventMap>(
    event: T,
    listener: (payload: EventMap[T]) => void
  ): void {
    this.emitter.on(event, listener);
  }

  public emit<T extends keyof EventMap>(event: T, payload: EventMap[T]): void {
    this.emitter.emit(event, payload);
  }

  public unsubscribe<T extends keyof EventMap>(
    event: T,
    listener: (payload: EventMap[T]) => void
  ): void {
    this.emitter.off(event, listener);
  }
}

export const eventBus = EventBus.instance;

export { eventBus as EventBus };
